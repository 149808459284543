import styled from 'styled-components';

interface CardProps {
    source?: string;
    textAlign?: string
}

export const Card = styled.div<CardProps>`
    width: 100%;
    // height: 434px;
    // cursor: pointer;
    border-radius: 8px;
    background: ${(props) => props.source ? `url(${props.source})` : null};
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CardContainer = styled.div<CardProps>`
    width:100%;
    max-width:848px;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.textAlign === 'center' ? 'center' : props.textAlign === 'left' ? 'flex-start' : 'flex-end'};
    gap:15px;
    padding:0 15px;
`;

export const CardTitle = styled.h3<CardProps>`
    text-align: ${(props) => props.textAlign ? props.textAlign : 'unset'};
    color: #0E4574;
    font-family: Poppins;
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media(max-width:1024px){
        font-size: 26px;
    }
`;

export const CardSubtitle = styled.p<CardProps>`
    text-align: ${(props) => props.textAlign ? props.textAlign : 'unset'};
    color: #1F1F1F;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

`
export const CardImg = styled.img`
    width:100%;
    max-width:84px;
    height:auto;
    object-fit:contain;
    display:block;
    margin: 0 0 0 auto;
`

