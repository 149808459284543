import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface SubHeaderProps {
    isDisabled?: boolean;
    isSelected?: boolean;
    isAgentPage?: boolean;
}

export const SubHeaderBackground = styled.div<SubHeaderProps>`
    background-color: ${(props) => (props.isAgentPage ? '#ffffff' : '#e0e0e0')};
`

export const SubHeaderContainer = styled.div`
    margin-top: 70px !important;
    padding: 12px 25px !important;
    display: flex;
    justify-content: center;
    column-gap: 36px;
    font-size: 14px;

    .swiper-button-prev {
        color: #96A83B !important;
    }

    .swiper-button-next {
        color: #96A83B !important;
    }

    @media screen and (max-width: 600px) {
        column-gap: 20px;
        padding: 12px 0 !important;
    }

    .isAgent{
        width: 100%;
        max-width: fit-content;

        p  {
            color: #A3C202;
            border: 1px solid #A3C202;
            padding: 8px 16px;
            border-radius: 8px;
        
            @media screen and (max-width: 767px){
                font-size: 14px;
                display:block;
                line-height:1.1;

            }
        }
    }
`;

export const SubHeaderLink = styled(NavLink)<SubHeaderProps>`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: #000000;

    opacity: ${(props) => (props.isDisabled ? '0.2' : '1')};
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};

    @media screen and (max-width: 600px) {
        width: min-content;
    }
`;

export const SubHeaderButton = styled.div<SubHeaderProps>`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: #000000;
    cursor: pointer;

    opacity: ${(props) => (props.isDisabled ? '0.2' : '1')};
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};

    @media screen and (max-width: 600px) {
        width: min-content;
    }
`;

export const SubHeaderTitle = styled.p<SubHeaderProps>`
    font-family: Poppins;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    color: ${(props) => (props.isSelected ? 'rgb(163, 194, 2)' : 'black')};
    font-weight: ${(props) => (props.isSelected ? '500' : '400')};
`;
