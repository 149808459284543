import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import rightPoint from 'assets/icons/right-point.svg';

export const FooterWrapper = styled.footer`
    background: #0e4574;
    color: #ffffff;
`;

export const FooterContainer = styled.div``;

export const FooterTop = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 68px;

    @media screen and (max-width: 600px) {
        padding-top: 30px;
        flex-direction: column;
    }
`;

export const FooterContact = styled.div`
    width: 50%;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;

export const FooterContactTitle = styled.p`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 31px;
    line-height: 46px;
    margin-bottom: 26px;

    @media screen and (max-width: 600px) {
        margin-top: 40px;
        margin-bottom: 0;
    }
`;

export const FooterInfo = styled.div`
    width: 50%;
    font-family: 'Poppins';
    font-size: 12px;
    line-height: 18px;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;

export const FooterInfoTitle = styled.p``;

export const FooterInfoImage = styled.img`
    margin-right: 10px;
`;

export const FooterInfoDiv = styled.div`
    width: 200px;
`;

export const FooterInfoLocation = styled.p`
    font-weight: 700;
    color: #a3c202;
    margin-top: 24px;
`;

export const FooterInfoAddress = styled.p`
    font-weight: 400;
    margin: 6px 0;
`;

export const FooterInfoSocials = styled.div`
    margin-top: 18px;
`;

export const FooterSubscribe = styled.div`
    border-top: 1px solid #ababab;
    padding-top: 30px;
`;

export const FooterLinks = styled.div`
    width: 450px;
    margin: 26px auto;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
`;

export const FooterUl = styled.ul`
    line-height: 26px;
`;

export const FooterLi = styled.li`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    color: #e0e0e0;
    cursor: pointer;

    &:before {
        position: absolute;
        content: '';
        width: 18px;
        height: 18px;
        top: 4px;
        left: -20px;
        background: url(${rightPoint}) no-repeat;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
    }
`;

export const FooterLink = styled(NavLink)`
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    position: relative;
    list-style: none;
`;

export const FooterALink = styled.a`
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    position: relative;
    list-style: none;
`;


export const FooterBottom = styled.div`
    margin: 40px 90px 8px 30px;
    display: flex;
    justify-content: flex-end;
    column-gap: 24px;

    @media screen and (max-width: 600px) {
        margin: 40px 30px 8px 30px;
    }
`;

export const FooterClickable = styled.a`
     color: #ffffff;
     text-decoration: unset;
 `;
