import styled from 'styled-components';

interface HeaderContainerProps {
    background: string;
}

export const HeaderWrapper = styled.div<HeaderContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: ${(props) => props.background};
    z-index: 2;
`;

export const HeaderContainer = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px !important;
    color: #ababab;
`;

export const HeaderImage = styled.img`
    width: 100%;
    max-width:180px;
    object-fit:contain;
    display:block;
    cursor: pointer;
    margin-right: 10px;
`;

export const PreviousPage = styled.p`
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    img {
        width: 32px;
    }
    
    @media(max-width:767px){
        font-size: 14px;
    }

`;

export const AgentHeaderContainer = styled.div`
display:flex;
gap:20px;
align-items:center;

    @media(max-width:767px){
        gap:10px
    }
`

export const ContactClickable = styled.div`
display:flex;
gap:5px;
align-items:center;

    img{
        width:100%;
        max-width:24px;
        height:auto;
        object-fit:content;
        display:block;
    }
`


export const CallLink = styled.a`
color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration:none;

@media(max-width:767px){
    display:none;
}
`
export const DivideLine = styled.div``