import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface CardProps {
    hover: boolean;
}

export const Card = styled.a`
    text-decoration: none;
    background-color:#F1F1F1;
    display:block;
    border-radius: 8px;
`;

export const CardContainer = styled.div<CardProps>`
    position: relative;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
    // opacity: ${(props) =>  props.hover ? '1' : '0.5'};
    // transform: ${(props) =>  props.hover ? 'scale(1)' : 'scale(.95)'};
    object-fit: cover;

    @media screen and (max-width: 650px) {
        width: 100%;
    }
`;

export const CardImage = styled(LazyLoadImage)`
    width: 100%;
    max-width:264px;
    aspect-ratio:1;
    height: auto;
    border-radius: 8px;
    object-fit:contain;
`;

export const CardTitle = styled.h3<CardProps>`
    // color: ${(props) => props.hover ? '#4f7fa7' : '#9E9E9E'};
    // font-size: ${(props) => props.hover ? '25px' : '20px'};
    color:  #4F7FA7;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align:left;
    width:100%;
    max-width:189px;
`;
export const TextImgWrap = styled.div`
    display:flex;
    gap:5px;
    justify-content: center;
    padding:15px 10px;
`
export const DownloadIcon = styled.img`
    display:block;
    width:100%;
    max-width:25px;
    height:25px;
    object-fit:contain;
    align-self: end;
`