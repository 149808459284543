import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HeaderContainer, HeaderWrapper, HeaderImage, PreviousPage, AgentHeaderContainer, ContactClickable, CallLink, DivideLine } from './styled';
import { NavBar } from '../index';
import { HamburgerMenu } from '../index';
import { useMediaQuery } from 'utils/media-query';

import profoundWhiteLogo from 'assets/icons/white-logo.svg';
import profoundAgentLogo from 'assets/icons/agent-logo.svg';
import backArrow from 'assets/icons/white-back-arrow.svg'
import phoneIcon from '../../assets/svg/phone.svg'
import divideLine from '../../assets/svg/divide-line.svg'


function Header() {
    const [background, setBackground] = useState('transparent');

    const navigate = useNavigate();
    const location = useLocation();
    const isTablet = useMediaQuery('tablet');

    const isHomePage = location.pathname === '/';
    const isAgentPage = location.pathname === '/agent' || location.pathname === '/agent/';

    const listenScrollEvent = () => {
        if (window.scrollY > 50) {
            setBackground('#0E4574');
        } else {
            setBackground('transparent');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () => {
            window.removeEventListener('scroll', listenScrollEvent);
        };
    }, []);

    return (
        <HeaderWrapper background={!isHomePage ? '#0e4574' : background}>
            <HeaderContainer className="container">
                <HeaderImage
                    src={isAgentPage ? profoundAgentLogo : profoundWhiteLogo}
                    alt="profound-white-logo"
                    onClick={() => {
                        if (isAgentPage) {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        } else {
                            navigate('/');
                        }
                    }}
                    loading="lazy"
                />
                {isAgentPage ? 
                    <AgentHeaderContainer>
                    <ContactClickable>
                        <a href="tel:8772087308"><img src={phoneIcon} alt="Phone" width="24" height="24" loading="eager"/></a>
                        <CallLink href="tel:8772087308">877.208.7308</CallLink>
                    </ContactClickable>
                    <DivideLine >
                        <img src={divideLine} alt="Phone" width="1" height="24" loading="eager"/>
                    </DivideLine>
                    <PreviousPage onClick={() => navigate(-1)}>
                        Back to Profound website
                        <img src={backArrow} alt='back-arrow' />
                    </PreviousPage>
                    </AgentHeaderContainer>
                : 
                    <>
                        {isTablet ? null : <NavBar />}
                        <HamburgerMenu />
                    </>
                }
            </HeaderContainer>
        </HeaderWrapper>
    );
}

export default Header;
