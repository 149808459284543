import { useState } from 'react';
import {
    Card,
    CardContainer,
    CardImage,
    CardTitle,
    TextImgWrap,
    DownloadIcon
} from './styled';
import { MarketingCardProps } from 'global/globalTypes';

function MarketingCard({
    image,
    background,
    title,
    alt,
    link,
    downloadIcon
}: MarketingCardProps) {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(true);
    };

    const onLeave = () => {
        setHover(false);
    };

    return (
        <Card href={link} target='_blank'>
            <CardContainer
                onMouseEnter={onHover}
                onMouseLeave={onLeave}
                hover={hover}
            >
                <CardImage
                    src={image}
                    alt={alt}
                    loading="lazy"
                    effect="blur"
                    placeholderSrc={background}
                    width={'100%'}
                    height={'100%'}
                />
            </CardContainer>
            <TextImgWrap>
                <CardTitle 
                    onMouseEnter={onHover}
                    onMouseLeave={onLeave}
                    hover={hover}
                >{title}</CardTitle>
                <DownloadIcon src={downloadIcon} alt="Download" loading="eager" width="25" height="25" />
            </TextImgWrap>
        </Card>
    );
}

export default MarketingCard;
