import {
    Card,
    CardContainer,
    CardTitle,
    CardSubtitle,
    CardImg
} from './styled';
import { MarketingOpportunitiesProps } from 'global/globalTypes';

function MarketingOpportunities({
    background,
    Icon,
    title,
    description,
    textAlign,
}: MarketingOpportunitiesProps) {

    return (
        <Card source={background}>
            <CardContainer source={background} textAlign={textAlign}>
                <CardImg src={Icon} alt="Note" width="84" height="84" loading="lazy"/>
                <CardTitle textAlign={textAlign}>{title}</CardTitle>
                <CardSubtitle textAlign={textAlign}>{description}</CardSubtitle>
            </CardContainer>
        </Card>
    );
}

export default MarketingOpportunities;
